jQuery(document).ready(function ($) {
	// mobile menu
	document.querySelector(".menu-trigger").addEventListener("click", (e) => {
		e.currentTarget.classList.toggle("active");
		document.querySelector(".menu-menu-container").classList.toggle("active");
	});
	
	// Homepage hero slider
	$(".hero-slider").slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 6000,
		arrows: false,
		dots: true,
	});

	$(".service").hover(function () {
		$(this).children().children().children(".more").slideToggle();
	});

	// Clients slider
	$(".clientes").slick({
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		autoplay: true,
		autoplaySpeed: 2000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				}
			}
		]
	});

	// Testimonials slider
	$(".testimonials").slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		autoplay: true,
		autoplaySpeed: 2000,
		responsive: [
			{
				breakpoint: 460,
				settings: {
					slidesToShow: 1,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
				}
			}
		]
	});

	const menuButtons = document.querySelectorAll('.menu-item a')

	const scrollInTo = (e) => {
		e.preventDefault()
		const goTo = e.currentTarget.getAttribute('href')
		const target = document.querySelector(goTo)
		console.log(target)
		document.querySelector(".menu-menu-container").classList.toggle("active");
		document.querySelector(".menu-trigger").classList.toggle("active");
		setTimeout(() => {
			target.scrollIntoView({behavior: "smooth"});
		}, 200);
	}
	
	menuButtons.forEach(button => button.addEventListener('click', scrollInTo))

});
